import React, { useState } from "react";
import "./home.css";
import axios from "axios";
import LinkImage from "../LinkImage/LinkImage";
import IEEELogo from "../../assets/svg/home-svg/home-IEEELogo.svg";
import BrandFont from "../../assets/svg/home-svg/home-brandfont.svg";
import HactoberFest from "../../assets/svg/home-svg/home-hactoberfestLogo.svg";
import GitHubLogo from "../../assets/svg/home-svg/home-github-white.svg";
import Timer from "../Timer/Timer";

import Left from "../../assets/svg/home-svg/left.svg";
import Right from "../../assets/svg/home-svg/right.svg";


const ieee_link = "https://ieeevit.org/";
const HactoberFest_link = "https://hacktoberfest.digitalocean.com/";
const FOSSFiesta_link = "https://fossfiesta.ieeevit.org/";
const enroll_link = process.env.REACT_APP_BACKEND_URL;


const Home = () => {
   console.log(enroll_link)
  //Github Auth
  const handleonClick = () => {
    axios.get(`${enroll_link}`, {}).then(function (response) {
      console.log(response);
      if (response.lastErrorObject.updatedExisting === true) {
        console.log(response);
      } else {
        console.log("failed", response);
      }
    });
  };

  return (
      <div className="home-container">
        
        <div className="home-row1">
          <div className="home-row1-heading">
            <h1>CODESPRINT</h1>
          </div>
          {/* <a href={enroll_link}>
            <button className="home-row1-btn" onClick={handleonClick}>
              <div className="home-row1-btn-text">Enroll</div>
              <img src={GitHubLogo} alt="" />
            </button>
          </a> */}
        <h3 className="home-row1-message">The event has ended!</h3>
        </div>
        <div className="home-row2">
          <LinkImage
            link={ieee_link}
            img={IEEELogo}
            img_class="home-row2-img home-row2-img1"
            link_class="home-row2-link"
          />
          <LinkImage
            link={FOSSFiesta_link}
            img={BrandFont}
            img_class="home-row2-img home-row2-img2"
            link_class="home-row2-link"
          />
          <LinkImage
            link={HactoberFest_link}
            img={HactoberFest}
            img_class="home-row2-img home-row2-img3"
            link_class="home-row2-link"
          />
        </div>
        {/* <div className="home-row3">
        <img src={Left} alt="" className="home-img1"/>
        <img src={Right} alt=""  className="home-img2"/>
        </div> */}
      </div>

  );
};

export default Home;
