//FAQ page's content goes from here
const INST_LIST = [{
    instruction: "Please follow the contributing guidlines as mentioned on the contributing.md of each repository."
  },
  {
    instruction: "Choose whichever issues you want to solve from the 7 IEEE-VIT repositories which have Hacktoberfest label on them."
  },
  {
    instruction: "Solve and contribute as much as you can in the stipulated time period."
  },
  {
    instruction: "For every merged pull request, 10 points will be awarded."
  },
  {
    instruction: "The leader board will be frozen exactly at 2:00 am on 3rd October'21."
  },
  {
    instruction: "The one on the top of the leaderboard will given exciting goodies."
  },
  {
    instruction: "Please be respectful with the repository maintainers and the fellow contributors."
  }
];

export default INST_LIST;