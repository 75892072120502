import React from "react";
import "./about.css";
import smLeaf1 from "../../assets/svg/about-svg/about-sm-leaf1.svg";
import smLeaf2 from "../../assets/svg/about-svg/about-sm-lear2.svg";
import lgLeaf1 from "../../assets/svg/about-svg/about-lg-leaf1.svg";
import lgLeaf2 from "../../assets/svg/about-svg/about-lg-leaf2.svg"; 

const About = () => {
  return (
    <div id="about">
      <div className="about-container">
        <div className="about-text-container">
          <h1 className="about-text-heading">About CODESPRINT</h1>
          <hr className="about-hr"/>
          <div className="about-text-subtext">
            <p className="about-text-subtext1">
            Code Sprint is an 8 hour long competition where participants should solve as many issues as possible by opening Pull Requests. Every contribution get you a certain number of points. After the stipulated time, the person with the highest number of points on the leaderboard is declared as the winner.


            </p>
            <p className="about-text-subtext2">
            All backgrounds and skills levels are encouraged to complete the challenge.
            </p>
          </div>
        </div>
        <div className="about-leaves">
          <div className="about-leaves-item1 about-leaves-item">
            <img src={lgLeaf1} alt="" className="about-leaves-item1-lg"/>
            <img src={smLeaf1} alt="" className="about-leaves-item1-sm"/>
          </div>
          <div className="about-leaves-item2 about-leaves-item">
            <img src={lgLeaf2} alt="" className="about-leaves-item2-lg"/>
            <img src={smLeaf2} alt="" className="about-leaves-item2-sm"/>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default About;