import React from "react";
import EnvelopeLogo from "../../assets/svg/register-svg/enroll-letter.svg";

import "./enroll.css";

// for new users - isExistingUser = false
// for existing users - isExistingUser = true

const Enroll = (props) => {
  const message1 = "You are successfully enrolled";
  const message2 = "Oh, I see you have already enrolled";

  return (
    <div>
      <div className="postregister-container">
        <div className="postregister-middle">
          <div className="brand">
            <img src={EnvelopeLogo} alt="" />
          </div>
          <div className="message">
            <h2>{props.isExistingUser ? message2 : message1}</h2>
            <h1>Good Luck Sprinting!</h1>
            {/* Add website link to leaderboard here  */}
            <a href="../#leaderboard">
              <button className="enroll-btn">Leaderboard</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enroll;
